/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    postcard: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M2 2a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2zM1 4a1 1 0 011-1h12a1 1 0 011 1v8a1 1 0 01-1 1H2a1 1 0 01-1-1zm7.5.5a.5.5 0 00-1 0v7a.5.5 0 001 0zM2 5.5a.5.5 0 01.5-.5H6a.5.5 0 010 1H2.5a.5.5 0 01-.5-.5m0 2a.5.5 0 01.5-.5H6a.5.5 0 010 1H2.5a.5.5 0 01-.5-.5m0 2a.5.5 0 01.5-.5H6a.5.5 0 010 1H2.5a.5.5 0 01-.5-.5M10.5 5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5zM13 8h-2V6h2z"/>',
    },
});
